import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import SaveIcon from '../../assets/heart.svg'
import EmptySaveIcon from '../../assets/heart-empty.svg'
import * as FavouriteStyles from './Favourites.module.scss'
// eslint-disable-next-line node/no-extraneous-import
import { useMutation } from '@apollo/client'
import { IMuddyUser } from '../../types'
import { 
  AddToFavouritesQuery,
  type IAddToFavouritesQueryData,
} from '../../graphql/queries/FavouritesQuery'

interface FavouritesProps {
  postId: string
  showText?: boolean
  siteId?: string
  setFetchTime?: Dispatch<SetStateAction<string>>
}

const Favourites = ({ postId, showText, siteId, setFetchTime }: FavouritesProps) => {
  const [isFavourite, setIsFavourite] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const musr:IMuddyUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('musr') ?? `{}`) : `{}`;
  const [ saves, setSaves ] = useState<string[]>([]);

  const [ addSave, { data: addToSaves } ] = useMutation<IAddToFavouritesQueryData>(AddToFavouritesQuery, {
    variables: { addpost: postId, site: siteId }
  });

  useEffect(() => {
    setSaves(musr?.saves ?? []);
  }, [])

  useEffect(() => {
    if (saves && saves.find((save: any) => save == postId)) {
      setIsFavourite(true)
    } else {
      setIsFavourite(false)
    }
  }, [saves])

  useEffect(() => {
    setTimeout(() => setError(null), 4000)
  }, [error])
 
  useEffect(() => {
    const savesStore = saves;
    const musrStore = musr;

    if (addToSaves?.addSaves.status === "ADDED") {
      const newMusr = {
        ...musrStore,
        saves: [...savesStore, postId]
      }
      localStorage.setItem('musr', JSON.stringify(newMusr));
      setSaves(newMusr.saves);
    } else if (addToSaves?.addSaves.status === 'REMOVED') {
      const filteredSaves = savesStore.filter(save => save != postId)
      const newMusr = {
        ...musrStore,
        saves: filteredSaves
      }
      localStorage.setItem('musr', JSON.stringify(newMusr));
      setSaves(newMusr.saves)
      setFetchTime && setFetchTime(new Date().toString());
    } else if (addToSaves?.addSaves.status === "ERROR") {
      setError(addToSaves.addSaves.message);
    }
  }, [addToSaves]);

  return (
    <span
      title={isFavourite ? `Remove From My Favourites` : `Add to My Favourites`}
      className={`${FavouriteStyles.Base} ${
        isFavourite ? FavouriteStyles.Saved : ''
      }`}
      onClick={() => addSave()}
    >
      {showText && (
        <span>
          {isFavourite ? `In Your Favourites` : `Add to My Favourites`}
        </span>
      )}
      <img src={isFavourite ? SaveIcon : EmptySaveIcon} alt={`Save`} />
      {error && isFavourite && (
        <span className={FavouriteStyles.Alert}>{error}</span>
      )}
    </span>
  )
}

export default Favourites
