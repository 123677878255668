import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Intro from '../components/organisms/intro/Intro'
// eslint-disable-next-line node/no-extraneous-import
import { useQuery } from '@apollo/client'
import * as ColStyles from '../components/Blocks/Blocks.module.scss'
import Hr from '../components/Hr/Hr'
import Block from '../components/Blocks/block/Block'
import { Helmet } from 'react-helmet'
import { Saves } from '../types'
import FavouritesQuery, {
  type IFavouritesQueryData
} from '../graphql/queries/FavouritesQuery'
import { Link } from 'gatsby'
import { LoginContext } from '../lib/utils'

const Faves = () => {
  const [status, setStatus] = useState<
    'OK' | 'LOGGED OUT' | 'NO FAVOURITES' | 'ERROR'
  >('OK')
  const { data, loading, error, refetch } =
    useQuery<IFavouritesQueryData>(FavouritesQuery)
  const { usr } = useContext(LoginContext)
  const [fetchTime, setFetchTime] = useState<string>('initial')

  const orphans = data ? data.getSaves.length % 4 : 0

  useEffect(() => {
    if (usr && usr.hasOwnProperty('viewer') && !usr.viewer) {
      setStatus('LOGGED OUT')
    } else if (
      !usr?.hasOwnProperty('viewer') &&
      error &&
      error.graphQLErrors.length
    ) {
      setStatus('ERROR')
    } else if (data?.getSaves && data.getSaves.length > 0) {
      setStatus('OK')
    } else if (data?.getSaves && data.getSaves.length < 1) {
      setStatus('NO FAVOURITES')
    } else {
      setStatus('ERROR')
    }
  }, [data])

  useEffect(() => {
    if (fetchTime !== 'initial') refetch()
  }, [fetchTime])
  return (
    <>
      <Helmet>
        <title>Your Favourites | Muddy Stilettos</title>
      </Helmet>
      <Header />
      <Intro>
        <h1>Your favourites</h1>
        <p />
      </Intro>
      <div
        className={`${ColStyles.Faves} ${
          orphans === 2 ? ColStyles.HasOrphan : ''
        }`}
      >
        {!loading && (
          <>
            {status === 'OK' &&
              data?.getSaves.map((post: Saves) => (
                <Block
                  key={post.id}
                  image={post.mediaImage}
                  id={post.id}
                  title={post.title}
                  uri={post.permalink ?? ''}
                  primaryCategory={post.category}
                  primaryCategoryURI={post.permalink}
                  isFavourite
                  setFetchTime={setFetchTime}
                />
              ))}
            {status === 'NO FAVOURITES' && (
              <div className={ColStyles.Spacer}>
                There is nothing yet in your Favourites
              </div>
            )}
            {status === 'ERROR' && (
              <div className={ColStyles.Spacer}>
                There was an issue trying to log you in. Please try to{' '}
                <Link to={`/log-in`}>Log In</Link> again.
              </div>
            )}
            {status === 'LOGGED OUT' && (
              <div className={ColStyles.Spacer}>
                You need to be logged in to save your favourites. Please{' '}
                <Link to={`/log-in`}>Log In</Link> or{' '}
                <Link to={`/sign-up`}>Sign Up</Link>.
              </div>
            )}
          </>
        )}
      </div>
      <Hr />
      <Footer />
    </>
  )
}

export default Faves
