// eslint-disable-next-line node/no-extraneous-import
import { gql } from '@apollo/client'
import { Saves } from '../../types'

const FavouritesQuery = gql`
  query Favourites {
    getSaves {
      id
      title
      mediaImage
      permalink
      category
    }
  }
`

export const AddToFavouritesQuery = gql`
  mutation AddToFavourites($addpost: String!, $site: String!) {
    addSaves(addpost: $addpost, site: $site) {
      status
      message
    }
  }
`
export interface IAddToFavouritesQueryData {
  addSaves: {
    status: 'ADDED' | 'REMOVED' | 'ERROR'
    message: string;
    __typename: 'SaveRes'
  }
}

export interface IFavouritesQueryData {
  getSaves: Saves[]
}

export default FavouritesQuery
