// extracted by mini-css-extract-plugin
export var BPTLNumber = "Blocks-module--BPTLNumber--36f0a";
export var BPTLNumberWrapper = "Blocks-module--BPTLNumberWrapper--253f7";
export var BackgroundColourBabyBeige = "Blocks-module--BackgroundColourBabyBeige--2bb30";
export var BackgroundColourBabyPink = "Blocks-module--BackgroundColourBabyPink--5f2d6";
export var BackgroundColourBeige = "Blocks-module--BackgroundColourBeige--41bb2";
export var BackgroundColourBlue = "Blocks-module--BackgroundColourBlue--6a906";
export var BackgroundColourDarkTeal = "Blocks-module--BackgroundColourDarkTeal--03575";
export var BackgroundColourIceBlue = "Blocks-module--BackgroundColourIceBlue--dad89";
export var BackgroundColourLightBeige = "Blocks-module--BackgroundColourLightBeige--3fc6b";
export var BackgroundColourLightGrey = "Blocks-module--BackgroundColourLightGrey--77655";
export var BackgroundColourLightPink = "Blocks-module--BackgroundColourLightPink--436e3";
export var BackgroundColourMediumBlue = "Blocks-module--BackgroundColourMediumBlue--aa94d";
export var BackgroundColourMediumPink = "Blocks-module--BackgroundColourMediumPink--2a97a";
export var BackgroundColourPink = "Blocks-module--BackgroundColourPink--fd2c3";
export var BackgroundColourWhite = "Blocks-module--BackgroundColourWhite--4f3cf";
export var BackgroundColourYellow = "Blocks-module--BackgroundColourYellow--c1baa";
export var Block = "Blocks-module--Block--a9149";
export var Button = "Blocks-module--Button--0f388";
export var Col = "Blocks-module--Col--fcbd1";
export var Cols = "Blocks-module--Cols--28d94";
export var CountyBlock = "Blocks-module--CountyBlock--e0707";
export var CountyBlocks = "Blocks-module--CountyBlocks--02d7a";
export var CountyImg = "Blocks-module--CountyImg--54137";
export var CountyText = "Blocks-module--CountyText--c519d";
export var DarkMode = "Blocks-module--DarkMode--29a80";
export var Faves = "Blocks-module--Faves--72bac";
export var FavouriteIcon = "Blocks-module--FavouriteIcon--ebaa7";
export var FullWidth = "Blocks-module--FullWidth--139aa";
export var HasOrphan = "Blocks-module--HasOrphan--635e9";
export var HasSlider = "Blocks-module--HasSlider--cfa05";
export var NoPadding = "Blocks-module--NoPadding--ce7c1";
export var Outer = "Blocks-module--Outer--67e05";
export var PageBlocks = "Blocks-module--PageBlocks--be064";
export var RightAligned = "Blocks-module--RightAligned--e291a";
export var Slider = "Blocks-module--Slider--dcdae";
export var Spacer = "Blocks-module--Spacer--54cf3";
export var SponsoredBy = "Blocks-module--SponsoredBy--8531b";
export var Spotlight = "Blocks-module--Spotlight--e54df";